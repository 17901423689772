var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "CButtonGroup",
        { staticClass: "mb-4" },
        [
          _c(
            "CButton",
            {
              staticClass: "shadow-none button-custom text-white",
              attrs: {
                color: "scroll-handler",
                variant: _vm.$route.name === "screener-list" ? "" : "outline",
              },
              on: {
                click: function ($event) {
                  return _vm.$router.push({ name: "screener-list" })
                },
              },
            },
            [_vm._v(" Screener ")]
          ),
          _c(
            "CButton",
            {
              staticClass: "shadow-none button-custom text-white",
              attrs: {
                color: "scroll-handler",
                variant: _vm.$route.name === "trends-coins" ? "" : "outline",
              },
              on: {
                click: function ($event) {
                  return _vm.$router.push({ name: "trends-coins" })
                },
              },
            },
            [_vm._v(" Trends ")]
          ),
        ],
        1
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }