<template>
  <div>
    <CButtonGroup class="mb-4">
      <CButton
        color="scroll-handler"
        class="shadow-none button-custom text-white"
        @click="$router.push({name: 'screener-list'})"
        :variant="$route.name === 'screener-list' ? '' : 'outline'"
      >
        Screener
      </CButton>
      <CButton
        color="scroll-handler"
        class="shadow-none button-custom text-white"
        @click="$router.push({name: 'trends-coins'})"
        :variant="$route.name === 'trends-coins' ? '' : 'outline'"
      >
        Trends
      </CButton>
    </CButtonGroup>

    <router-view />
  </div>
</template>

<script>
export default {
  name: "PageCoinList",
}
</script>
